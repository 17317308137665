<template>
    <div class="video-container">
        <video controls="controls" width="90%" height="90%" autoplay="autoplay">
            <source :src="url_video" type="video/mp4">
        </video>

        <!-- Modal upload -->
        <v-dialog
            v-model="dialog"
            width="600px"
        >
            <v-card>
                <v-card-title class="green darken-1">
                    Subir Video Tutorial
                </v-card-title>
                <v-card-text>
                    <v-row class="mx-2">
                        <v-file-input v-model="file" accept=".pdf" label="Archivo PDF"></v-file-input>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="dialog = false"
                    >Cancelar</v-btn>
                    <v-btn
                        color="primary"
                        @click="uploadManual"
                    >Subir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- / Modal -->
        
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            :color="color"
        >
            {{ validation_message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>

        <v-btn
            class="mx-2"
            color="green darken-1"
            dark
            right
            fab
            small
            @click="dialog = !dialog"
             v-if="user.rol && user.rol.id == 1"
        >
            <i class="material-icons">upload</i>
        </v-btn>
    </div>
</template>
<style scoped>

</style>
<script>
import {mapActions, mapState, mapMutations} from 'vuex';

export default {
    data:()=>({
        panel: 0,
        url_video: '',
        file: null,
        dialog: false,
        snackbar: false,
        color: 'green',
        validation_message: ''
    }),
    mounted(){
        if(window.innerHeight < 768){
            //this.pdf_height = 600;
        }
        this.cargarVideo();
    },
    methods:{
        async cargarVideo(){
            this.url_video = this.storage_url.replace('backend/storage/app/public', 'produccion') + 'ayuda/videos_tutoriales/rol_wellboat/carga_smolt/11_bitacora_carga.mp4';
            console.log(this.url_video);
        },
        async uploadManual(){
            this.headers.headers['Content-Type'] = "multipart/form-data";
            let url = `${this.base_url}uploads/ayuda/video`;
            let formData = new FormData();
            formData.append("video", this.file);
            formData.append("nombre", '11_bitacora_carga.mp4');
            formData.append("ruta", 'ayuda/videos_tutoriales/rol_wellboat/carga_smolt/');
            await this.axios.post(url, formData, this.headers).then(() => {
                this.file = null;
                this.upload = false;
                location.reload();
            });
        },
        ...mapActions(['iniciarSession']),
        ...mapMutations(['mostrarLoading', 'ocultarLoading'])
    },
    computed:{
        ...mapState(['base_url', 'headers', 'storage_url', 'user'])
    },
    components:{
    }
}
</script>